.css-1e8yyfy {
    text-transform: uppercase;
    color: white;
    @media screen {
        
    }
}
.header-public-profil-details {
    @media screen and (max-width: 500px) {
        .css-ihed2c {
            align-items: baseline;
            height: max-content;
            .chakra-stack {
                align-items: center;
                margin: 0;
                padding: 0;
                .chakra-avatar {
                    width: 150px;
                    height: 150px;
                }
            }
        }
    }
}
