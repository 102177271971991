.full-width {
    width: 100% !important;

    .error {
        color: #e53e3e;
        font-size: 0.875rem;
        line-height: normal;
    }
}

.vat-number-input {
    .chakra-input__left-addon {
        width: 150px;
        .css-b62m3t-container {
            width: 100%;
        }
        .css-13cymwt-control {
            border: none;
            width: 100%;
        }
    }

    .chakra-form-control {
        .chakra-input {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .chakra-alert {
        background-color: floralwhite;
        border: solid 1px #e2e8f0;
        color: #947443;
        font-weight: 500;
        .chakra-alert__icon {
            color: #947443;
        }
    }
}

/* Custom scrollbar styles */
.custom-scrollbar__menu-list::-webkit-scrollbar {
    width: 5px;
}

.custom-scrollbar__menu-list::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.custom-scrollbar__menu-list::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.custom-scrollbar__menu-list::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.text-muted {
    color: #888;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
}