.public-profil-details {
    @media screen and (max-width: 768px) {
        .css-j458ja {
            flex-direction: column;
            margin-left: 2rem;
            .chakra-stack {
                width: 100%;
            }
            // @media screen and (max-width: 500px) {
            //     margin-top: 6rem;
            // }
        }
    }
}