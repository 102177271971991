.custom-container {
  padding: 15px 10px 10px 10px;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  position: relative;
  margin: 2rem 0;
  background: "#fff";

  .title {
    display: flex;
    align-items: center;
    color: #5a5867;
    font-size: 12px;
    background-color: white;
    margin-right: 10px;
  }

  .css-7v8eks {
    width: 250px;
  }

  .css-1jqq78o-placeholder {
    font-size: 0.8rem;
  }

  /* switch input css */

  .use-filter-switch-box {
    position: relative;

    .chakra-switch {
      span {
        &.css-p27qcy {
          width: 3.5rem;
          height: 20px;
          pointer-events: all;
          border: solid 1px #63b1bd;
          background-color: unset !important;

          .css-7roig {
            background-color: #63b1bd;
            position: absolute;
            top: 5px;
            &[data-checked] {
              left: 1.8rem;
            }
          }
        }
      }
    }
    .use-filter-custom-label {
      color: #63b1bd;
      font-size: 14px;
      position: relative;
      &.no-label {
        left: 3rem;
      }
      &.yes-label {
        left: 1.5rem;
      }
    }
  }
}