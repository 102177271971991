.eventEditorToolbar{
  border-bottom: 1px solid #DEE2E6;
}
.eventEditorWrapper {
  background: #FFFFFF;
  border: 1px solid #DEE2E6;
  border-radius: 4px;
  min-height: 141px;
}

.eventEditorWrapper__error {
  border: 1px solid red;
}