.carousel {
    overflow: unset !important;
}

li.dot {
    background: #9f8256 !important;
    transform: translateY(40px) !important;
    color: red !important;
}

.chakra-modal__content {
    border-top: solid 5px #9f8256;
}

.cui-steps__step-icon-container {
    border: none !important;
    background: transparent !important;
}

.cui-steps__step-icon-container[data-highlighted] {
    border: #9f8256 !important;
    background: #9f8256 !important;
}

.cui-steps__step-icon-container span {
    font-size: 35px !important;
}

.cui-steps__horizontal-step::after {
    background-color: black !important;
    height: 1.5px !important;
}

.carousel.carousel-slider .control-arrow {
    transform: translateX(-38px);
    background: transparent !important;
    opacity: 0.7 !important;
}

.carousel .control-next.control-arrow {
    transform: translateX(40px);
}

.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #9f8256 !important;
}

.carousel .control-next.control-arrow:before {
    border-left: 8px solid #9f8256 !important;
}

.carousel .control-next.control-arrow {
    right: 0;
}

.w-auto {
    width: auto !important;
}

.cursor-pointer {
    cursor: pointer;
}

.w-15 {
    width: 15rem !important;
}

.h-40 {
    height: 40px !important;
}

.rdw-editor-main {
    padding: 0 10px !important;
}

.rdw-link-decorator-wrapper img.rdw-link-decorator-icon {
    top: -15px !important;
    margin-right: -5px !important;
}