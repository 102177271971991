.user-card {
    .special-badge {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem 1rem;
        background-color: #f2f5f7;
        border-radius: 4px;
        color: black;
        font-weight: 500;
        font-size: 12px;
        flex: 1 1;
    }
}