.news-list {
    &::-webkit-scrollbar {
        width: 10px !important;
    }
    &::-webkit-scrollbar-track {
        border-radius: 10px;
    }
}

.css-uo84k3 {
    font-size: 15px !important;
}

.vvtiv-white-logo {
    @media screen and (max-width: 600px) {
        position: relative !important;
    }
}